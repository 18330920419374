import React from "react"
// import { Link } from "gatsby"

// import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import { useTranslation } from "react-i18next"
// import LayoutForPageImage from "../components/Layouts/LayoutForPageImage/LayoutForPageImage"
// import ImagePageStep6 from "../components/PageStep6/ImagePageStep6"
// import { Container } from 'react-bootstrap';
import LayoutTitle from "../components/Layouts/LayoutTitle/LayoutTitle"
import { Button, Form  } from 'react-bootstrap'



const IndexPage = (props) => {
  const { t } = useTranslation()

  return (
    <div style={{ minHeight: '95vh'}} >
      <SEO title="How to solve a Rubik's cube   Как собрать кубик Рубика" />
      <LayoutTitle><h1 >{t('page-form.h1')}  </h1></LayoutTitle>

      {/* <LayoutForPageImage>
       <ImagePageStep6 />
      </LayoutForPageImage> */}
      {/* <div style={{ textAlign: 'center'}}>
      

      </div> */}
      <Form style={{ width:'80%', maxWidth: '500px', margin: '0 auto'}} 
        action="https://formspree.io/xlerzlrl"
        method="POST">
        <Form.Group>
          <Form.Label></Form.Label>
          <Form.Control name="name" type="text" placeholder={t('page-form.name')} />
          <Form.Label></Form.Label>
          <Form.Control name="email" type="email" placeholder={t('page-form.email')} />
          <Form.Label> </Form.Label>
          <Form.Control name="message" as="textarea" rows="3" placeholder={t('page-form.text')} />
        </Form.Group>
        <Button variant="primary" type="submit">{t('page-form.submit')}</Button>

        
        {/* <span role="img" aria-label="viva" style={{ fontSize: '1rem', margin: "0 auto", display: 'block', padding: '1rem 0', textAlign: 'center' }}>❤{t('page-form.thx')}</span> */}
        
      </Form>
      <span style={{ fontSize: '1rem', margin: "0 auto", display: 'block', padding: '1rem 1rem 0 1rem', textAlign: 'center' }}> {t('page-form.thx')}</span>
      <span role="img" aria-label="viva" style={{ fontSize: '7rem', margin: "0 auto", display: 'block', textAlign: 'center' }}>💋</span>
    </div>
  )
}

export default IndexPage
